import {SodaDom as Dom} from './SodaDom';

type Surface = 'portfolio' | 'contact';

export function preloadSurfaceImages(surface: Surface): void {
  switch (surface) {
    case 'portfolio':
      Dom.preloadImages([
        '/images/portfolio/portfolio_nav.png',
        '/images/portfolio/info_box.png',
        '/images/portfolio/summary_bg.png',
        '/images/portfolio/artwork_bg.png',
      ]);
      return;
    case 'contact':
      Dom.preloadImages(['/images/contact/required.png']);
      return;
  }
}

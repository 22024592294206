import {SodaDom as Dom} from './SodaDom';

type ContactField = HTMLInputElement | HTMLTextAreaElement;

export class ContactForm {
  fields: Array<ContactField> = [];
  requiredMessage: HTMLElement | null = null;
  sentMessage: HTMLElement | null = null;

  constructor() {
    const form = Dom.byId('contact-form');
    this.fields = this.getFields();
    this.requiredMessage = Dom.byId('contact-required');
    this.sentMessage = Dom.byId('contact-sent');
    if (!form || !this.requiredMessage || this.fields.length === 0) {
      return;
    }
    this.setupForm(form);
  }

  getFields(): Array<ContactField> {
    return ['name', 'from', 'message']
      .map(field => <ContactField>Dom.byId(`contact-${field}`))
      .filter(field => field != null);
  }

  setupForm(form: HTMLElement): void {
    Dom.on(form, 'submit', event => {
      if (!this.checkAndUpdateFields()) {
        event.preventDefault();
      }
    });
    Dom.on(form, 'mousedown', () => {
      if (this.sentMessage) {
        this.sentMessage.style.display = 'none';
      }
    });
  }

  checkAndUpdateFields(): boolean {
    let hasErrors = false;
    this.fields.forEach(field => {
      const isValidField = this.isValidField(field);
      if (!isValidField) {
        hasErrors = true;
      }
      const fieldRow = <HTMLElement | null>field.parentNode;
      if (fieldRow) {
        Dom.toggleClass(fieldRow, 'required', !isValidField);
      }
    });
    if (this.requiredMessage) {
      this.requiredMessage.style.display = hasErrors ? 'block' : 'none';
    }
    return !hasErrors;
  }

  isValidField(field: ContactField): boolean {
    return field.id === 'contact-from'
      ? this.isValidEmail(field.value)
      : field.value.trim() !== '';
  }

  isValidEmail(email: string): boolean {
    return /^[^\s,;]+@([^\s.,;]+\.)+[\w-]{2,}$/i.test(email);
  }
}

import {SodaDom as Dom} from './SodaDom';

type CarouselNavigateCallback = (itemIndex: number) => void;
type CarouselDirection = 'next' | 'prev';
const DIRECTIONS: Array<CarouselDirection> = ['next', 'prev'];

export class SodaCarousel {
  nodes: {[key: string]: HTMLElement} = {};
  currentIndex: number = 0;
  maxIndex: number = 0;
  itemWidth: number = 0;
  onNavigate?: CarouselNavigateCallback;

  constructor(carousel: HTMLElement, onNavigate?: CarouselNavigateCallback) {
    const container = Dom.select('.carousel-container', carousel);
    const frame = Dom.select('.carousel-frame', carousel);
    const items = Dom.selectAll('.carousel-item', carousel);

    if (!container || !frame || !items || items.length <= 1) {
      return;
    }

    this.nodes = {carousel, container};
    this.currentIndex = 0;
    this.maxIndex = items.length - 1;
    this.itemWidth = frame.offsetWidth;
    this.onNavigate = onNavigate;

    this.setupNavigation();
  }

  setupNavigation(): void {
    const {carousel} = this.nodes;
    DIRECTIONS.forEach(direction => {
      (Dom.selectAll(`.carousel-${direction}`, carousel) || []).forEach(
        button => {
          Dom.on(button, 'click', () => this.navigate(direction));
          Dom.on(button, 'mouseenter', () => this.hover(direction, true));
          Dom.on(button, 'mouseleave', () => this.hover(direction, false));
        },
      );
    });
    this.updateNavigation();
    this.showNavigation();
  }

  updateNavigation(): void {
    const {
      currentIndex,
      nodes: {carousel},
      maxIndex,
    } = this;
    Dom.toggleClass(carousel, 'carousel-next-end', currentIndex === maxIndex);
    Dom.toggleClass(carousel, 'carousel-prev-end', currentIndex === 0);
  }

  showNavigation() {
    Dom.addClass(this.nodes.carousel, 'carousel-ready');
  }

  getNextIndex(direction: CarouselDirection): number {
    return (this.currentIndex += direction === 'next' ? 1 : -1);
  }

  isEndIndex(direction: CarouselDirection): boolean {
    const {currentIndex, maxIndex} = this;
    return currentIndex === (direction === 'next' ? maxIndex : 0);
  }

  animateToIndex(index: number): void {
    const {
      nodes: {container},
      itemWidth,
    } = this;
    container.style.transform = `translateX(-${itemWidth * index}px)`;
  }

  hover(direction: CarouselDirection, show: boolean) {
    Dom.toggleClass(this.nodes.carousel, `carousel-${direction}`, show);
  }

  navigate(direction: CarouselDirection): void {
    if (this.isEndIndex(direction)) {
      return;
    }
    this.currentIndex = this.getNextIndex(direction);
    this.animateToIndex(this.currentIndex);
    this.updateNavigation();
    if (this.onNavigate) {
      this.onNavigate(this.currentIndex);
    }
  }
}

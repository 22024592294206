import {SodaDom as Dom} from './SodaDom';

export function printBtn(): void {
  const container = Dom.byId('resume-header');
  const printBtn = Dom.html(`
    <a href="#" class="print-page">
      <span>Print Page</span>
    </a>
  `);
  if (!container || !printBtn) {
    return;
  }
  Dom.on(printBtn, 'click', event => {
    event.preventDefault();
    window.print();
  });
  container.appendChild(printBtn);
}

export function scrollBtn(): void {
  const topOfPage = Dom.select('#top-of-page a');
  if (!topOfPage) {
    return;
  }
  Dom.on(topOfPage, 'click', event => {
    event.preventDefault();
    Dom.scrollTop();
  });
}

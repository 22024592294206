export class SodaDom {
  static ready(callback: () => void): void {
    document.addEventListener('DOMContentLoaded', callback);
  }

  static on(
    node: HTMLElement,
    type: string,
    callback: (event: Event) => void,
    options?: boolean | AddEventListenerOptions,
  ) {
    node.addEventListener(type, callback, options);
  }

  static byId(id: string): HTMLElement | null {
    return document.getElementById(id);
  }

  static select(
    selector: string,
    scope: HTMLElement | Document = document,
  ): HTMLElement | null {
    const node = scope.querySelector(selector);
    return node ? <HTMLElement>node : null;
  }

  static selectAll(
    selector: string,
    scope: Document | HTMLElement = document,
  ): Array<HTMLElement> | null {
    const nodeList = scope.querySelectorAll(selector);
    const nodes = [];
    for (let i = 0; i < nodeList.length; i++) {
      nodes.push(<HTMLElement>nodeList[i]);
    }
    return nodes.length > 0 ? nodes : null;
  }

  static html(html: string): HTMLElement | null {
    const node = new DOMParser().parseFromString(html, 'text/html').body
      .firstChild;
    return node ? <HTMLElement>node : null;
  }

  static text(node: HTMLElement, text: string): void {
    node.textContent = text;
  }

  static addClass(node: HTMLElement, className: string): void {
    node.classList.add(className);
  }

  static removeClass(node: HTMLElement, className: string): void {
    node.classList.remove(className);
  }

  static toggleClass(
    node: HTMLElement,
    className: string,
    force?: boolean,
  ): void {
    force === undefined
      ? node.classList.toggle(className)
      : node.classList[force ? 'add' : 'remove'](className);
  }

  static preloadImages(images: Array<string>): void {
    images.forEach(image => (new Image().src = image));
  }

  static scrollTop(node: Window | HTMLElement = window): void {
    if ('scrollBehavior' in document.documentElement.style) {
      node.scroll({behavior: 'smooth', left: 0, top: 0});
    } else {
      node.scroll(0, 0);
    }
  }
}

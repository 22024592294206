import {SodaDom as Dom} from './SodaDom';
import {SodaCarousel as Carousel} from './SodaCarousel';

export class FeaturedProjects {
  nodes: {[key: string]: HTMLElement} = {};
  descriptions: Array<string> = [];

  constructor() {
    const carousel = Dom.byId('featured');
    const description = Dom.byId('featured-description');
    if (!carousel || !description) {
      return;
    }
    this.descriptions = this.getDescriptions(carousel);
    if (this.descriptions.length <= 1) {
      return;
    }
    this.nodes = {description};
    new Carousel(carousel, this.onNavigate);
  }

  getDescriptions(carousel: HTMLElement): Array<string> {
    return (Dom.selectAll('.carousel-item', carousel) || []).map(item => {
      const img = Dom.select('img', item);
      return img ? img.getAttribute('alt') || '' : '';
    });
  }

  onNavigate = (index: number) => {
    Dom.text(this.nodes.description, this.descriptions[index]);
  };
}
